// import the variables, so we can access the ui theme colors
@import 'application/material-dashboard/variables';
@import 'application/material-overrides/mixins';

// fields with text suffix/unit
.currency {
  > .input-suffix {
    padding: 8px 0;
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    margin: 9px 0;
    width: 3.5rem;
  }

  input[type='text'],
  input[type='number'] {
    padding-right: 3.5rem;
  }
}

// summary / box
$summary-c-border: #b0b0b0;
$summary-c-shadow: rgba(0, 0, 0, 0.1);
$summary-c-title: #323232;
$summary-c-list-text: #666;

.summary {
  @include clearafter;
  padding: 15px 20px;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px $summary-c-border;
  margin: 0 0 17px;

  h5 {
    font-size: 14px;
    color: $summary-c-title;
    font-weight: 700;
    margin: 0;
  }

  &-input:hover {
    background-color: rgba($brand-primary, 0.05);
    cursor: pointer;
    box-shadow: inset 0 0 0 1px $brand-primary;
  }

  &--selected,
  &--selected.summary-input:hover {
    background-color: transparent;
    box-shadow: 0 2px 4px $summary-c-shadow, inset 0 0 0 2px $brand-primary;
  }

  .action {
    display: block;
    font-size: 12px;
    font-weight: 400;
    float: right;
    text-transform: uppercase;
  }

  .info {
    display: block !important;
    float: none !important;
    margin: 0 !important;
    width: auto !important;
  }
}

.list-summary {
  list-style-type: none;
  padding: 4px 0;
  margin: 0;

  li {
    padding: 0 0 0 16px;
    position: relative;
    line-height: 21px;
    color: $summary-c-list-text;

    &::before {
      content: '—';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

// REVIEW: styles used on the calculation forms. Might make some logic to move them to a common front/backend file?
$gray: #9e9e9e;

.element--add {
  position: relative;

  .element--remove {
    position: absolute;
    right: 0;
    top: 22px;

    a > i {
      color: $gray;
    }
  }
}

// Addition for links that should have similar style to buttons. This class is not themed by the ui theme, but is
// available within bootstrap.
.btn-link {
  font-size: 12px !important;
  text-decoration: none !important;
  text-transform: uppercase;
  color: $brand-primary !important;

  i {
    padding-right: 3px;
  }
}

// Addition for displaying radio button collections inline within simple_form_bootstrap wrappers
.radio--horizontal {
  > label {
    display: block !important;
  }

  .radio {
    display: inline-block !important;
    margin-right: 10px;
  }
}
